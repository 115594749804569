<template>
  <div>
    <div class="h-text-center">
      <div class="h-m-t-10">
        <van-image :src="require('@/assets/images/pay_success.png')" style="height:120px;width:120px" />
      </div>
      <div class="h-font-lg h-font-bold-sm h-m-t-10">
        <span class="h-primary-color">恭喜您！</span>
        <span>扣款成功！</span>
      </div>
      <div v-if="account" class="h-font-lg h-m-t-10"><span class="h-font-md h-text-secondary-color">账户余额：</span>{{ account }}元</div>
      <div class="h-font-md h-text-secondary-color h-m-t-5">订单号：{{ orderNo }}</div>
    </div>
    <div v-if="contractId" class="btn">
      <van-button type="primary" block @click="toContractDetail">查看</van-button>
    </div>
  </div>
</template>

<script>
import { request } from "@/api/service";
export default {
  name: "ProductPayFinish",
  data() {
    return {
      pageData: {
        interval: null
      },
      orderNo: "",
      account: null,
      orderId: null,
      contractId: null,
      contractNo: null
    };
  },
  created() {
    const { orderNo, orderId, contractId, contractNo } = this.$route.query;
    this.orderNo = orderNo;
    this.orderId = orderId;
    this.contractId = contractId;
    this.contractNo = contractNo;
    this.initAccount();
    this.initInterval();
  },
  methods: {
    initInterval() {
      this.pageData.interval = setInterval(this.calcInterval, 1000 * 3);
    },
    calcInterval() {
      this.clearInterval();
      this.toContractList();
    },
    clearInterval() {
      this.pageData.interval && clearInterval(this.pageData.interval);
    },
    initAccount() {
      request({
        url: "/afis-carservice/fund/fundAccount/check",
        method: "POST"
      })
        .then(res => {
          this.account = res.balance;
        })
        .catch(() => {});
    },
    toContractList() {
      this.$router.replace({
        path: "/product/contract/list",
        query: {
          name: this.contractNo
        }
      });
    },
    toContractDetail() {
      this.$router.replace({
        path: "/product/contract/detailPdf",
        query: {
          id: this.contractId
        }
      });
    }
  },
  beforeDestroy() {
    this.clearInterval();
  }
};
</script>

<style lang="scss" scoped>
.btn {
  margin-top: 30px;
  padding: 0 10px;
}
</style>
